<template>
    <CardComponent
        :title="title"
        :icon="icon"
        :tip="tip"
        :filter="true"
        :options="options"
        link="/finance/accounting/daily/list"
        class="goods-operate-card"
        @handleFilter="handleFilter"
    >
        <section v-loading="laoding">
            <el-radio-group
                v-model="radio1"
                size="small"
                @change="handleChange"
            >
                <el-radio-button
                    v-for="(tab, index) in tabs"
                    :key="index"
                    :label="tab.value"
                >
                    {{ tab.label }}
                </el-radio-button>
            </el-radio-group>
            <gb-echart
                ref="distributorSale"
                config="goods-operate-config"
                height="100%"
                width="100%"
            />
        </section>
    </CardComponent>
</template>

<script>
import CardComponent from '../components/card'
import GbEchart from '@/components/Echart'
import accountSvc from 'services/accounting'
export default {
    name: '',
    components: { CardComponent, GbEchart },
    props: {
        title: {
            type: String,
            default: ''
        },
        options: {
            type: Array,
            default: null
        },
        group: {
            type: Object,
            default: null
        },
        groupId: {
            type: Number,
            default: null
        }
    },
    data () {
        return {
            // title: 'TOP5商品经营图',
            tip: 'TOP5商品经营图',
            icon: 'iconfont icon-zoushitu',
            radio1: 'XT000002',
            // tabs: [],
            radioValue: '',
            laoding: true
        }
    },
    computed: {
        tabs () {
            return [
                {
                    label: '销售收入',
                    value: 'XT000002'
                },
                {
                    label:
                        this.$route.path === '/desktop/operator'
                            ? '产品边际贡献'
                            : '店铺边际贡献',
                    value:
                        this.$route.path === '/desktop/operator'
                            ? 'XT000004'
                            : 'XT000011'
                },
                {
                    label:
                        this.$route.path === '/desktop/operator'
                            ? '店铺边际贡献'
                            : '净利润',
                    value:
                        this.$route.path === '/desktop/operator'
                            ? 'XT000011'
                            : 'XT000006'
                }
            ]
        }
    },
    watch: {
        'group.groupId' () {
            if (this.group.groupId) {
                this.initData()
            }
        }
    },
    methods: {
        handleFilter (val) {
            this.$nextTick(() => {
                const echart = this.$refs.distributorSale
                echart.option.series = []
                echart.refresh()
            })
        },
        async initData () {
            try {
                this.laoding = true
                const param = {
                    groupId: this.group.groupId,
                    accountingItemNo: this.radio1
                }
                const apiName = this.group.leaf
                    ? 'getDailyReportTop5Leaf'
                    : 'getDailyReportTop5NotLeaf'

                const { data = {} } = await accountSvc[apiName](param)
                this.laoding = false
                this.dataHandle(data)
            } catch (error) {
                console.log(error, '=====error')
                this.laoding = false
            }
        },
        handleChange (val) {
            this.radioValue = val
            this.initData()
        },
        dataHandle (data) {
            if (data) {
                const legendData = []
                let xAisData = []
                const seriesData = []
                if (data.produceDTOList.length > 0) {
                    data.produceDTOList.forEach((item, index) => {
                        legendData.push(item.name)
                        let color = ''
                        const obj = {
                            name: '',
                            type: 'line',
                            smooth: true,
                            data: [],
                            itemStyle: {
                                color: ''
                            }
                        }
                        switch (index) {
                        case 0:
                            color = '#DF5A68'
                            break
                        case 1:
                            color = '#E6A23C'
                            break
                        case 2:
                            color = '#3595E1'
                            break
                        case 3:
                            color = '#39A36B'
                            break
                        case 4:
                            color = '#7158DE'
                            break
                        default:
                            color = '#7158DE'
                            break
                        }
                        obj.name = item.name
                        obj.itemStyle.color = color
                        obj.data = item.timeValueList.map(v =>
                            v.value.toFixed(2)
                        )
                        xAisData = item.timeValueList.map(
                            v =>
                                `${v.date
                                    .split('/')[0]
                                    .substr(5)}~${v.date
                                    .split('/')[1]
                                    .substr(5)}`
                        )
                        seriesData.push(obj)
                    })
                }
                this.$nextTick(() => {
                    const echart = this.$refs.distributorSale
                    echart.option.legend.data = legendData
                    echart.option.xAxis.data = xAisData
                    echart.option.series = seriesData
                    echart.refresh()
                })
            }
        }
    }
}
</script>
<style lang="less" scoped>
.goods-operate-card {
    grid-row-end: span 2;
    grid-column-end: span 3;
}

section {
    position: relative;

    /deep/ .el-radio-group {
        position: absolute;
        top: 1vh;
        z-index: 2;
        display: flex;
        justify-content: center;
        width: 100%;

        .el-radio-button__inner {
            // padding: 9px 20px;
            width: 1.4rem;
            padding: 0.09rem 0;
            font-size: 0.12rem;
            text-align: center;
            background: transparent;
        }

        .el-radio-button__orig-radio:checked + .el-radio-button__inner {
            background-color: #69acf1;
            border-color: #69acf1;
        }

        .el-radio-button:nth-child(1) {
            .el-radio-button__inner {
                border-radius: 0.15rem 0 0 0.15rem;
            }
        }

        .el-radio-button:last-child {
            .el-radio-button__inner {
                border-radius: 0 15px 15px 0;
            }
        }
    }
}
</style>
